import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { Row, Col } from 'antd/lib/grid';

import Heading2 from '../../Heading2';

import Section from '../Section';

import * as componentStyles from './ClientsSection.style';

const ClientsSection = ({ clients }) => (
    <FelaComponent style={componentStyles.wrapper}>
        <Row id="clients-header">
            <Heading2 color="white">
                <FormattedMessage id="event.clients.title" />
            </Heading2>
        </Row>
        <Section id="clients" customStyle={componentStyles.section}>
            <Row>
                {clients.map(({ logo, name }, i) => (
                    <Col key={i} xs={12} sm={12} md={8} lg={6}>
                        <div className="ImgContainer">
                            <img src={logo} alt={name} />
                        </div>
                    </Col>
                ))}
            </Row>
        </Section>
    </FelaComponent>
);

ClientsSection.propTypes = {
    clients: PropTypes.arrayOf(
        PropTypes.shape({
            logo: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }),
    ),
};

ClientsSection.defaultProps = {
    clients: [],
};

export default ClientsSection;
