import React from 'react';

import Arrow from './Arrow';

import arrowIcon from '../../images/ic_arrow_right.svg';
import arrowStyle from './ArrowRight.style';

const ArrowRight = props => <Arrow arrowIcon={arrowIcon} arrowStyle={arrowStyle} {...props} />;

export default ArrowRight;
