import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { Row, Col } from 'antd/lib/grid';

import Heading2 from '../../Heading2';

import Section from '../Section';

import Feature, { featureShape } from './Feature';

import * as componentStyles from './MoreFeaturesSection.style';

const MoreFeaturesSection = ({ features }) => (
    <FelaComponent style={componentStyles.wrapper}>
        <Section id="more-features" customStyle={componentStyles.section}>
            <Heading2>
                <FormattedMessage id="event.moreFeatures.title" />
            </Heading2>
            <Row>
                {features.map((feature, i) => (
                    <Col key={i} sm={24} md={12} lg={8}>
                        <Feature {...feature} />
                    </Col>
                ))}
            </Row>
        </Section>
    </FelaComponent>
);

MoreFeaturesSection.propTypes = {
    features: PropTypes.arrayOf(featureShape),
};

MoreFeaturesSection.defaultProps = {
    features: [],
};

export default MoreFeaturesSection;
