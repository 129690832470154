import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import Heading2 from '../../Heading2';
import Text from '../../Text';

import Section from '../Section';

import style from './BrandingSection.style';

const BrandingSection = ({ image }) => (
    <FelaComponent style={style}>
        <Section id="branding">
            <Heading2>
                <FormattedMessage id="event.branding.title" />
            </Heading2>
            <Text color="greyDarker">
                <FormattedMessage id="event.branding.desc" />
            </Text>
            <div className="ImgContainer">
                <img src={image} alt="" role="presentation" />
            </div>
        </Section>
    </FelaComponent>
);

BrandingSection.propTypes = {
    image: PropTypes.string.isRequired,
};

export default BrandingSection;
