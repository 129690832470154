import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import Heading3 from '../../../Heading3';
import Text from '../../../Text';

import style from './Feature.style';

const propTypes = {
    titleId: PropTypes.string.isRequired,
    descId: PropTypes.string.isRequired,
    image: PropTypes.node.isRequired,
};

export const featureShape = PropTypes.shape(propTypes);

const Feature = ({ titleId, descId, image }) => (
    <FelaComponent style={style}>
        <div className="ImgContainer">{image}</div>
        <Heading3>
            <FormattedMessage id={titleId} />
        </Heading3>
        <Text size="small" color="grey">
            <FormattedHTMLMessage tagName="span" id={descId} />
        </Text>
    </FelaComponent>
);

Feature.propTypes = propTypes;

export default Feature;
