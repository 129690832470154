export const wrapper = ({ theme }) => ({
    backgroundColor: theme.colors.white,

    textAlign: 'center',

    overflow: 'hidden',

    '& .Heading2': {
        marginBottom: '2.5rem',
    },

    '& section': {
        paddingBottom: '5.5rem',
    },
});

export const section = () => ({
    maxWidth: '77rem',
    min1680px: {
        maxWidth: '90rem',
    },
});
