export default () => ({
    '&.slick-prev': {
        left: '-3rem',
        min1390px: {
            left: 0,
        },
        min1680px: {
            left: '-4rem',
        },
    },
});
