import { arrowHeight } from '../../Arrows/Arrow.style';

export const featuresButton = ({ theme }) => ({
    marginTop: '4rem',
});

export const wrapper = ({ theme }) => ({
    backgroundColor: theme.colors.white,

    textAlign: 'center',

    overflow: 'hidden',

    '& .Heading2': {
        marginBottom: '3rem',
    },

    '& .slick-slider .slick-prev': {
        top: `calc(45% - ${arrowHeight / 2}px)`,
    },
    '& .slick-slider .slick-next': {
        top: `calc(45% - ${arrowHeight / 2}px)`,
    },

    '& .slick-slider .slick-dots': {
        bottom: 'unset',
        marginTop: '3rem',
        position: 'relative',
    },
});
