export const wrapper = ({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',

    '& .Heading1': {
        maxWidth: '34.5rem',
        margin: '6rem auto',

        fontWeight: 400,

        min768px: {
            margin: '7rem auto',
        },
    },

    '& .Heading1 strong': {
        fontWeight: 700,
    },

    '& section': {
        paddingTop: '7rem',
        paddingBottom: '2.5rem',
    },
});

export const row = () => ({
    width: '100%',
    maxWidth: '64rem',
});
