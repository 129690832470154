import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';
import { FormattedMessage } from 'react-intl';
import Slick from 'react-slick';

import Heading2 from '../../Heading2';

import Section from '../Section';

import Feature, { featureShape } from '../Feature';
import { ArrowLeft, ArrowRight } from '../../Arrows';

import * as componentStyles from './FeaturesSection.style';
import Link from '../../Link';
import { LinkType } from '../../Link/Link.style';

const settings = {
    slidesToShow: 3,
    infinite: true,
    speed: 500,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                arrows: false,
                dots: true,
            },
        },
        {
            breakpoint: 992,
            settings: {
                arrows: false,
                slidesToShow: 2,
                dots: true,
                touchThreshold: 15,
            },
        },
        {
            breakpoint: 640,
            settings: {
                arrows: false,
                slidesToShow: 1,
                dots: true,
            },
        },
    ],
};

const FeaturesSection = ({ features, moreLink, titleId, buttonId }) => (
    <FelaComponent style={componentStyles.wrapper}>
        <Section id="features">
            <Heading2>
                <FormattedMessage id={titleId} />
            </Heading2>
            <Slick {...settings}>
                {features.map((feature, i) => (
                    <Feature key={i} isGatsbyImage={true} image={feature.image.src} {...feature} />
                ))}
            </Slick>
            <FelaComponent style={componentStyles.featuresButton}>
                {({ className }) => (
                    <FormattedMessage id={buttonId}>
                        {txt => (
                            <Link type={LinkType.GATSBY} styleType="button" to={moreLink} className={className}>
                                {txt}
                            </Link>
                        )}
                    </FormattedMessage>
                )}
            </FelaComponent>
        </Section>
    </FelaComponent>
);

FeaturesSection.propTypes = {
    features: PropTypes.arrayOf(featureShape),
    moreLink: PropTypes.string.isRequired,
};

FeaturesSection.defaultProps = {
    features: [],
};

export default FeaturesSection;
