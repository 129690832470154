import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';
import { FormattedMessage } from 'react-intl';
import Slick from 'react-slick';

import Heading2 from '../../Heading2';

import Section from '../Section';

import Feedback, { feedbackShape } from './Feedback';
import { ArrowLeft, ArrowRight } from '../../Arrows';

import style from './FeedbackSection.style';

const settings = {
    slidesToShow: 1,
    infinite: true,
    speed: 500,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    dots: false,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                arrows: false,
                dots: true,
            },
        },
    ],
};

const FeedbackSection = ({ feedback }) => (
    <FelaComponent style={style}>
        <Section id="feedback">
            <Heading2 color="white">
                <FormattedMessage id="event.feedback.title" />
            </Heading2>
            <Slick {...settings}>
                {feedback.map((item, i) => (
                    <Feedback key={i} {...item} />
                ))}
            </Slick>
        </Section>
    </FelaComponent>
);

FeedbackSection.propTypes = {
    feedback: PropTypes.arrayOf(feedbackShape),
};

FeedbackSection.defaultProps = {
    feedback: [],
};

export default FeedbackSection;
