import imageContainerBackground from '../../../../images/img_mockup@2x.png';

const activeStyle = {
    paddingBottom: 1,
    borderBottom: 'none',
};

export const feedback = ({ theme }) => ({
    textAlign: 'left',
    '& .Text': {
        boxSizing: 'border-box',
        padding: '0 1.5rem',
    },
    '& .Text:first-child': {
        textAlign: 'justify',
        marginBottom: '2.75rem',
    },
    '& .Text:last-child': {
        width: '100%',
    },
    '& .InfoPane': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',

        paddingBottom: '6.25rem',
    },
    '& .InfoPane img': {
        float: 'left',
        maxWidth: '5rem',
        display: 'inline-block',
        marginRight: '1.5rem',
    },
    '& .Text a': {
        paddingBottom: 0,
        color: theme.colors.red,
        borderBottom: `1px solid ${theme.colors.red}`,
        textDecoration: 'none',
        ':hover': activeStyle,
        ':focus': activeStyle,
        ':active': activeStyle,
    },

    '& .ImagePane': {
        display: 'none',
        height: '36rem',
    },

    '& .ImageContainer': {
        display: 'none',

        position: 'absolute',
        width: '23.375rem',
        height: '44.0625rem',

        backgroundImage: `url(${imageContainerBackground})`,
        backgroundSize: 'contain',

        borderRadius: '2.8125rem',
        ':first-child': {
            top: '3rem',
            right: '11.75rem',
        },
        ':last-child': {
            top: 0,
            right: '0.2rem',
            zIndex: 1,
        },
    },

    '& .ImageContainer img': {
        width: '17.9rem',
        margin: 'auto',
        paddingTop: '4.3rem',
    },

    min992px: {
        '& .ImagePane': {
            display: 'flex',
        },

        '& .InfoPane': {
            height: '36rem',
        },

        '& .ImagePane .ImageContainer:last-of-type': {
            display: 'block',
        },
    },
    min1390px: {
        '& .ImagePane .ImageContainer:first-of-type': {
            display: 'block',
        },
    },
});

export const person = ({ theme }) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    paddingLeft: '1.5rem',
    color: theme.colors.white,
});

export const personDetailName = ({ theme }) => ({
    fontWeight: 'bold',
    display: 'block',
    lineHeight: '1.15rem',
    fontSize: '1.15rem',
    marginBottom: '0.55rem',
});

export const personDetailPosition = ({ theme }) => ({
    display: 'block',
    fontSize: '1.15rem',
    lineHeight: '1.35rem',
});
