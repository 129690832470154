export default ({ theme }) => ({
    backgroundColor: theme.colors.greyDarkest,

    textAlign: 'center',

    overflow: 'hidden',

    '& .Heading2': {
        marginBottom: '3rem',
    },

    '& section': {
        paddingBottom: 0,
    },
    '& .slick-slider': {
        marginTop: '5.5rem',
        min1200px: {
            marginTop: 0,
        },
    },
    '& .slick-slide.slick-current': {
        overflow: 'hidden',
    },
    '& .slick-slider .slick-dots': {
        top: '-3rem',
        bottom: 'unset',
    },
    '& .slick-slider .slick-dots li button:before': {
        color: 'white',
    },
});
