export default () => ({
    '&.slick-next': {
        right: '-3rem',
        min1390px: {
            right: 0,
        },
        min1680px: {
            right: '-4rem',
        },
    },
});
