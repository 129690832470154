export const wrapper = ({ theme }) => ({
    backgroundColor: theme.colors.greyDarkLighter,
    textAlign: 'center',
    '& .ImgContainer': {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',

        boxSizing: 'border-box',
        maxWidth: '16.5rem',
        height: '10rem',

        margin: 'auto',
        padding: '2.5rem',

        min576px: {
            height: '15rem',

            padding: '4rem',
        },
    },
    '& .ImgContainer img': {
        width: '100%',
        opacity: 0.6,
    },
    '& section': {
        paddingBottom: '3rem',
    },
    '& .Heading2': {
        marginBottom: '3rem',
        min576px: {
            marginBottom: 0,
        },
    },
    '& #clients-header': {
        paddingTop: '6rem',
        paddingBottom: '1rem',
    },
});

export const section = () => ({
    paddingTop: '1rem',
    min1390px: {
        maxWidth: '72rem',
    },
});
