export default ({ theme }) => ({
    maxWidth: '8.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '2rem',
    '& span:first-of-type:not(:last-of-type)': {
        display: 'inline-block',
        marginBottom: '1.5rem',

        color: theme.colors.green,

        fontWeight: 900,
        fontSize: '3.75rem',
        lineHeight: '1em',
    },
    '& img': {
        height: '3.5rem',
        marginTop: '0.25rem',
        marginBottom: '1.5rem',
    },
});
