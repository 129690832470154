import React from 'react';

import Arrow from './Arrow';

import arrowIcon from '../../images/ic_arrow_left.svg';
import arrowStyle from './ArrowLeft.style';

const ArrowLeft = props => <Arrow arrowIcon={arrowIcon} arrowStyle={arrowStyle} {...props} />;

export default ArrowLeft;
