import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';
import { FormattedHTMLMessage } from 'react-intl';

import Text from '../../../Text';

import style from './Info.style';

const Info = ({ titleId, descId, image }) => (
    <FelaComponent style={style}>
        {({ className }) => (
            <Text className={className} color="white" size="small">
                {image && <img src={image} alt="" role="presentation" />}
                {titleId && <FormattedHTMLMessage tagName="span" id={titleId} />}
                <br />
                <FormattedHTMLMessage tagName="span" id={descId} />
            </Text>
        )}
    </FelaComponent>
);

Info.propTypes = {
    titleId: PropTypes.string,
    descId: PropTypes.string,
    image: PropTypes.string,
};

export default Info;
