export default ({ theme }) => ({
    backgroundColor: theme.colors.greyLight,

    textAlign: 'center',

    '& .Heading2': {
        marginBottom: '0.5rem',
    },
    '& .Text': {
        marginBottom: '4.5rem',
    },
    '& .ImgContainer': {
        display: 'inline-block',
        maxWidth: '64rem',
    },

    '& .ImgContainer img': {
        width: '100%',
    },

    '& section': {
        paddingBottom: 0,
    },
});
