import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';

import { Row, Col } from 'antd/lib/grid';

import Heading1 from '../../Heading1';

import BackgroundSection from '../../Home/BackgroundSection';
import Info from './Info';

import * as componentStyles from './HeaderSection.style';
import { FelaComponent } from 'react-fela';

const HeaderSection = ({ titleId, backgroundImage, info }) => (
    <BackgroundSection
        customStyle={componentStyles.wrapper}
        backgroundImage={backgroundImage}
        backgroundFullscreen={true}
    >
        <Heading1 color="white">
            <FormattedHTMLMessage tagName="span" id={titleId} />
        </Heading1>
        <FelaComponent style={componentStyles.row}>
            {({ className }) => (
                <Row className={className}>
                    {info.map((item, i) => (
                        <Col key={i} xs={24} sm={12} md={6}>
                            <Info {...item} />
                        </Col>
                    ))}
                </Row>
            )}
        </FelaComponent>
    </BackgroundSection>
);

HeaderSection.propTypes = {
    titleId: PropTypes.string.isRequired,
    backgroundImage: PropTypes.object.isRequired,
    info: PropTypes.arrayOf(
        PropTypes.shape({
            titleId: PropTypes.string,
            descId: PropTypes.string,
        }),
    ),
};

HeaderSection.defaultProps = {
    info: [],
};

export default HeaderSection;
