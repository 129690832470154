import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { Row, Col } from 'antd/lib/grid';

import Text from '../../../Text';
import { connect as connectFela } from 'react-fela';
import * as componentStyles from './Feedback.style';

const propTypes = {
    authorId: PropTypes.string.isRequired,
    textId: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    screen1: PropTypes.string,
    screen2: PropTypes.string,
    styles: PropTypes.objectOf(PropTypes.string),
};

export const feedbackShape = PropTypes.shape(propTypes);

const Feedback = ({ authorId, textId, avatar, screen1, screen2, styles }) => (
    <FelaComponent style={componentStyles.feedback}>
        <Row gutter={94}>
            {screen1 && screen2 && (
                <Col className="ImagePane" md={24} lg={12}>
                    <div className="ImageContainer">
                        <img src={screen1} alt="" role="presentation" />
                    </div>
                    <div className="ImageContainer">
                        <img src={screen2} alt="" role="presentation" />
                    </div>
                </Col>
            )}
            <Col className="InfoPane" md={24} lg={10}>
                <Text color="white">
                    <FormattedMessage id={textId} />
                </Text>
                <div className={styles.person}>
                    <img src={avatar} alt="" role="presentation" />
                    <div>
                        <span className={styles.personDetailName}>
                            <FormattedMessage id={`${authorId}.name`} />
                        </span>
                        <span className={styles.personDetailPosition}>
                            <FormattedMessage id={`${authorId}.text`} />
                        </span>
                    </div>
                </div>
            </Col>
        </Row>
    </FelaComponent>
);

Feedback.propTypes = propTypes;

Feedback.defaultProps = {
    screen1: undefined,
    screen2: undefined,
};

export default connectFela(componentStyles)(Feedback);
